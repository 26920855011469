<template>
  <div class="WebCastKnowMore" :id="id">
    <div :class="{ 'content-margin': this.$screen.width >= 1024 }">
      <div class="WebCastKnowMore__title poligon-title">
        <h3>{{ title }}</h3>
      </div>
    </div>
    <div @mouseup="updateScroll" @touchend="updateScroll" v-if="isInfoReady" data-aos="fade-up">
      <carousel-3d class="WebCastKnowMore__carousel" v-bind="settings" :controls-visible="true" :key="uniqueKey">
        <slide v-for="(slideItem, i) in slideItems" class="WebCastKnowMore__carousel-slide" :key="i" :index="i" @click.native="openYoutubeModal">
          <button class="button-play">
            <iconic name="btn_play" />
          </button>
          <div class="background-image" :style="`background-image:url(${slideItem.media})`"></div>
          <h4>{{ slideItem.title }}</h4>
        </slide>
      </carousel-3d>
    </div>
    <postModal ref="postModal" />
    <div class="WebCastKnowMore__scroll content-margin">
      <input type="range" :max="slideItems.length - 1" :min="0" class="scroll-input" @input="changeScroll" v-model="slideIndex" />
    </div>
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
import postModal from "../modals/PostModal.vue";
export default {
  components: { Carousel3d, Slide, postModal },
  props: ["title", "skeleton", "slideItems", "id"],
  data: function () {
    return {
      rangeValue: 0,
      maxRangeValue: 0,
      isInfoReady: false,
      slickTrackElement: null,
      settings: {
        width: 380,
        height: 260,
        disable3d: true,
        space: "405",
        animationSpeed: 400,
        perspective: 50,
        clickable: false,
        controlsVisible: true,
      },
      slideIndex: 0,
      rangeValue: 0,
      uniqueKey: 0,
    };
  },
  mounted() {
    if (this.$screen.width < 768) {
      this.settings.space = "335";
    }
    setTimeout(() => {
      const leftDistance = this.getLeftDistance();
      document.querySelector(`#${this.id} .WebCastKnowMore__carousel`).style.transform = "translateX(" + leftDistance + "px)";
    }, 1000);

    setTimeout(() => {
      this.isInfoReady = true;
    }, 500);
  },

  methods: {
    /**
     * Funcion encargada de obtener la distancia de los elementos con la clase content-margin respecto al lado izquierdo de la pagina
     */
    getLeftDistance() {
      const contentMarginElement = document.querySelector(".content-margin");
      // Se obtiene la información de la posición del elemento
      const getElementPosition = contentMarginElement.getBoundingClientRect();
      // Calculamos la distancia al borde superior de la página
      return getElementPosition.left + window.scrollX;
    },

    /**
     * Funcion encargada de hacer scroll al slider cuando se mueve el input range
     * @param {*} evt
     */
    changeScroll(evt) {
      const valueInputRange = +evt.target.value;
      document.querySelectorAll(`#${this.id} .carousel-3d-slide`).forEach((slide, index) => {
        if (slide.classList.contains("current")) {
          if (valueInputRange > index) {
            document.querySelector(`#${this.id} .next`).click();
          }
          if (valueInputRange < index) {
            document.querySelector(`#${this.id} .prev`).click();
          }
        }
      });
    },
    toggleShowComments(evt, flag) {
      if (this.$screen.width >= 1024) {
        this.openYoutubeModal(evt, true);
      } else {
        this.$refs.WebCastComments.toggleShowComments(evt, flag);
      }
    },
    openYoutubeModal(evt) {
      this.$refs.postModal.openYoutubeModal();
    },

    /**
     * Funcion encargada de actualizar el valor y la posicion del input range cuando se arrastra el slider
     */
    updateScroll() {
      setTimeout(() => {
        let foundCurrent = false;
        document.querySelectorAll(`#${this.id} .WebCastKnowMore__carousel-slide`).forEach((item, i) => {
          if (item.classList.contains("current") && !foundCurrent) {
            this.slideIndex = i;
            foundCurrent = true;
          }
        });
      }, 280);
    },
  },
};
</script>
<style lang="scss">
.WebCastKnowMore {
  margin-top: 50px;
  overflow: hidden;

  &__scroll {
    display: flex;
    align-items: center;
    height: 20px;
    margin-top: 200px;
    width: 90%;

    input[type="range"],
    input[type="range"]::-webkit-slider-runnable-track,
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 100%;
    }

    input[type="range"]::-webkit-slider-thumb {
      background-color: #3d3d3d;
      width: 30%;
      height: 100%;
      cursor: pointer;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      background-color: #c1c1c1;
      height: 4px;
    }
  }
  &__carousel {
    margin-left: 10px;
    width: 100%;

    &-slide {
      margin-left: 10px;
      cursor: pointer;

      .button-play {
        display: block;
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        height: 50px;
        width: 50px;
        top: 0;
        left: 50%;
        top: 50%;
        z-index: 10;
        transform: translate(-50%, -50%);
        .iconic {
          font-size: 20px;
          color: #fff;
        }
      }
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 10px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.15) 70.16%, rgba(0, 0, 0, 0.7) 89%);
      }
      img {
        width: 100%;
        max-height: 100%;
        border-radius: 15px;
      }
      .background-image {
        background-position: top center;
        background-size: cover;
        height: 100%;
        width: 100%;
        border-radius: 12px;
      }
      h4 {
        position: absolute;
        bottom: 0;
        color: #fff;
        width: 100%;
        z-index: 20;
        padding: 20px;
        text-transform: uppercase;
      }
      .gradient {
        border-radius: 12px;
        background: linear-gradient(
          360deg,
          rgba(255, 0, 0, 0.7) 0%,
          rgba(255, 255, 255, 0) 16%,
          rgba(0, 0, 0, 0.15) 100%,
          rgba(238, 79, 79, 0.7) 100%
        );
      }
    }
    .carousel-3d-slide {
      background: transparent;
      border: none;
    }
  }

  @include Mobile {
    &__carousel {
      margin-left: 0;
    }
    .carousel-3d-slide {
      width: 320px !important;
    }
  }

  @include Desktop {
    &__carousel {
      margin-left: 0;
      &-slide {
        height: 300px;
        h4 {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
